import React, { FC } from 'react'
import useToggle from '@hooks/useToggle'
import { useIntl } from 'react-intl'
import { useStore } from '@nanostores/react'
import { monthMessage } from 'src/i18n/messages'
import Tooltip from '@components/ui/Tooltip'
import c from 'clsx'

import { yearRate } from './Calculator'

import {
  amount as amountStore,
  duration as durationStore,
  advancePay as advancePayStore
} from '../../store'

const Comparison: FC = () => {
  const [tableOpen, toggleTable] = useToggle()
  const { formatNumber, formatMessage } = useIntl()

  const amount = useStore(amountStore)
  const duration = useStore(durationStore)
  const advancePay = useStore(advancePayStore)
  const advancePart = Math.round((advancePay / amount) * 100)

  const bankInterestRate = 0.2
  const capitalInterestRate = 0.16

  // процент на вложенный капитал
  const capitalInterest = ((amount * capitalInterestRate) / 12) * duration

  // амортизация
  const depreciation = (((amount / 120) * 100) / 36) * duration
  const leaseDepreciation =
    (((amount / 120) * 100) / 12) * Math.min(12, duration)

  const loanInterest = ((amount * bankInterestRate) / 12) * duration

  // yearRate = feraInterestRate
  const leaseInterest = (((amount - advancePay) * yearRate) / 12) * duration
  const leaseInterestExpense = (leaseInterest / 120) * 100

  const VATReduction = (amount / 120) * 20
  const leaseVATReduction = ((amount + leaseInterest) / 120) * 20

  const incomeTaxReduction = (loanInterest + depreciation) * 0.2
  const ownIncomeTaxReduction = depreciation * 0.2
  const leaseIncomeTaxReduction =
    (leaseInterestExpense + leaseDepreciation) * 0.2

  const Interest = (
    <tr className='contents'>
      <td
        className={c(
          'w-46 lg:w-auto border-b-1 border-grayscale-300 py-3 text-p250 font-normal flex items-end justify-between mr-1',
          tableOpen && 'ml-6 w-40'
        )}
      >
        Плата за финансирование
        <Tooltip className='ml-1 self-center'>
          Сколько рублей составляет ставка за финансирование при данной
          стоимости оборудования
        </Tooltip>
      </td>

      <td className='w-38 lg:w-48 border-l-2 border-r-2 border-red-100 border-opacity-20 px-4 lg:px-5 pt-3'>
        <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-red-100 border-opacity-20'>
          {formatNumber(Math.round(loanInterest))} ₽
        </div>
      </td>
      <td className='w-38 lg:w-48 border-l-2 border-r-2 border-yellow-500 border-opacity-20 px-4 lg:px-5 pt-3'>
        <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-yellow-500 border-opacity-20 flex items-start'>
          {formatNumber(Math.round(capitalInterest))} ₽
          <Tooltip className='ml-1 top-[3px]'>
            Это <b>стоимость денег</b>. Компания могла бы потратить собственные
            средства не на покупку оборудования, а вложить в альтернативные
            доходные инструменты, например положить на депозит под 7% годовых
          </Tooltip>
        </div>
      </td>
      <td className='w-38 lg:w-48 border-l-2 border-r-2 border-green-400 border-opacity-30 px-4 lg:px-5 pt-3'>
        <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-green-500 border-opacity-30'>
          {formatNumber(Math.round(leaseInterest))} ₽
        </div>
      </td>
    </tr>
  )

  return (
    <div>
      <div className='lg:flex justify-between mb-7 px-6 xl:px-0'>
        <p className='text-p350 lg:text-p100 text-grayscale-150 lg:w-120 mb-4 lg:mb-0'>
          Сравнение лизинга и его альтернатив для имущества стоимостью{' '}
          {formatNumber(amount)} ₽ сроком в{' '}
          {formatMessage(monthMessage, { value: duration })} с авансом{' '}
          {advancePart}%*
        </p>
        <button
          onClick={toggleTable}
          className='px-5 h-9 bg-grayscale-450 hover:bg-grayscale-400 flex items-center justify-center rounded-full text-p350 w-full lg:w-auto'
        >
          <span className='pr-1'>
            {tableOpen ? 'Свернуть' : 'Развернуть'} таблицу
          </span>
          <svg
            className={c(
              tableOpen && '-rotate-180',
              'transition-transform duration-150'
            )}
            width='10'
            height='11'
            viewBox='0 0 10 11'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8.75 3.625L5 7.375L1.25 3.625'
              stroke='#323232'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </button>
      </div>
      <table className='grid grid-cols-comparison overflow-x-auto scrollbar-hide mb-5 text-left gap-x-4 px-6 xl:px-0'>
        <thead className='contents'>
          <tr className='contents'>
            <th className='w-46 lg:w-auto border-b-1 border-grayscale-300 py-3 text-p250 font-normal flex items-end mr-1'>
              Тип финансирования
            </th>
            <th className='w-38 lg:w-48 font-display text-h500 lg:text-h400 font-medium rounded-t-xl border-2 border-red-100 border-b-0 border-opacity-20 px-5 lg:px-7 pb-3 pt-4 lg:pt-7'>
              Банковский кредит
            </th>
            <th className='w-38 lg:w-48 font-display text-h500 lg:text-h400 font-medium rounded-t-xl border-2 border-yellow-500 border-b-0 border-opacity-20 px-5 lg:px-7 pb-3 pt-4 lg:pt-7'>
              Собственные средства
            </th>
            <th className='w-38 lg:w-48 font-display text-h500 lg:text-h400 font-medium rounded-t-xl border-2 border-green-400 border-b-0 border-opacity-30 px-5 lg:px-7 pb-3 pt-4 lg:pt-7'>
              Лизинг от Fera
            </th>
          </tr>
        </thead>
        <tbody className='contents'>
          {tableOpen && (
            <tr className='contents'>
              <td className='w-46 mt-1px lg:w-auto border-b-1 border-grayscale-300 py-3 text-p250 font-normal flex items-end justify-between mr-1'>
                Срок финансирования
              </td>
              <td className='w-38 lg:w-48 border-l-2 border-r-2 border-red-100 border-opacity-20 px-4 lg:px-5 pt-3'>
                <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-red-100 border-opacity-20'>
                  {formatMessage(monthMessage, { value: duration })}
                </div>
              </td>
              <td className='w-38 lg:w-48 border-l-2 border-r-2 border-yellow-500 border-opacity-20 px-4 lg:px-5 pt-3'>
                <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-yellow-500 border-opacity-20'></div>
              </td>
              <td className='w-38 lg:w-48 border-l-2 border-r-2 border-green-400 border-opacity-30 px-4 lg:px-5 pt-3'>
                <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-green-500 border-opacity-30'>
                  {formatMessage(monthMessage, { value: duration })}
                </div>
              </td>
            </tr>
          )}
          {tableOpen && (
            <tr className='contents'>
              <td className='w-46 mt-1px lg:w-auto border-b-1 border-grayscale-300 py-3 text-p250 font-normal flex items-end justify-between mr-1'>
                Ставка финансирования
                <Tooltip className='ml-1 self-center'>
                  В случае с кредитом является <b>кредитной ставкой</b>, а в
                  случае с лизингом <b>ставкой удорожания</b>
                </Tooltip>
              </td>
              <td className='w-38 lg:w-48 border-l-2 border-r-2 border-red-100 border-opacity-20 px-4 lg:px-5 pt-3'>
                <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-red-100 border-opacity-20'>
                  20%
                </div>
              </td>
              <td className='w-38 lg:w-48 border-l-2 border-r-2 border-yellow-500 border-opacity-20 px-4 lg:px-5 pt-3'>
                <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-yellow-500 border-opacity-20 flex items-start w-full'>
                  16%
                  <Tooltip className='ml-1 top-[3px]'>
                    Это <b>стоимость денег</b>. Компания могла бы потратить
                    собственные средства не на покупку оборудования, а вложить в
                    альтернативные доходные инструменты, например положить на
                    депозит под 7% годовых
                  </Tooltip>
                </div>
              </td>
              <td className='w-38 lg:w-48 border-l-2 border-r-2 border-green-400 border-opacity-30 px-4 lg:px-5 pt-3'>
                <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-green-500 border-opacity-30'>
                  17%
                </div>
              </td>
            </tr>
          )}
          {tableOpen && (
            <tr className='contents'>
              <td className='w-46 mt-1px lg:w-auto border-b-1 border-grayscale-300 py-3 text-p250 font-normal flex items-end justify-between mr-1'>
                Аванс
              </td>
              <td className='w-38 lg:w-48 border-l-2 border-r-2 border-red-100 border-opacity-20 px-4 lg:px-5 pt-3'>
                <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-red-100 border-opacity-20'></div>
              </td>
              <td className='w-38 lg:w-48 border-l-2 border-r-2 border-yellow-500 border-opacity-20 px-4 lg:px-5 pt-3'>
                <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-yellow-500 border-opacity-20'></div>
              </td>
              <td className='w-38 lg:w-48 border-l-2 border-r-2 border-green-400 border-opacity-30 px-4 lg:px-5 pt-3'>
                <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-green-500 border-opacity-30'>
                  {advancePart}% → {formatNumber(advancePay)} ₽
                </div>
              </td>
            </tr>
          )}
          <tr className='contents'>
            <td className='w-46 mt-1px lg:w-auto border-b-1 border-grayscale-300 py-3 text-p250 font-normal flex items-end justify-between mr-1'>
              Стоимость имущества
            </td>
            <td className='w-38 lg:w-48 border-l-2 border-r-2 border-red-100 border-opacity-20 px-4 lg:px-5 pt-3'>
              <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-red-100 border-opacity-20'>
                {formatNumber(amount)} ₽
              </div>
            </td>
            <td className='w-38 lg:w-48 border-l-2 border-r-2 border-yellow-500 border-opacity-20 px-4 lg:px-5 pt-3'>
              <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-yellow-500 border-opacity-20'>
                {formatNumber(amount)} ₽
              </div>
            </td>
            <td className='w-38 lg:w-48 border-l-2 border-r-2 border-green-400 border-opacity-30 px-4 lg:px-5 pt-3'>
              <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-green-500 border-opacity-30'>
                {formatNumber(amount)} ₽
              </div>
            </td>
          </tr>
          {!tableOpen && Interest}
          <tr className='contents'>
            <td className='w-46 mt-1px lg:w-auto border-b-1 border-grayscale-300 py-3 text-p250 font-normal flex items-end justify-between mr-1'>
              Экономия по налогу на прибыль
              <Tooltip className='ml-1 self-center'>
                <b>Экономия по налогу</b> составляет 20% от платы за
                финансирование и дополнительных расходов на амортизацию. В
                случае с лизингом берётся 20% от платы за финансирование и
                расходов на амортизацию, за вычетом НДС
              </Tooltip>
            </td>
            <td className='w-38 lg:w-48 border-l-2 border-r-2 border-red-100 border-opacity-20 px-4 lg:px-5 pt-3'>
              <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-red-100 border-opacity-20'>
                ({formatNumber(Math.round(incomeTaxReduction))}) ₽
              </div>
            </td>
            <td className='w-38 lg:w-48 border-l-2 border-r-2 border-yellow-500 border-opacity-20 px-4 lg:px-5 pt-3'>
              <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-yellow-500 border-opacity-20'>
                ({formatNumber(Math.round(ownIncomeTaxReduction))}) ₽
              </div>
            </td>
            <td className='w-38 lg:w-48 border-l-2 border-r-2 border-green-400 border-opacity-30 px-4 lg:px-5 pt-3'>
              <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-green-500 border-opacity-30'>
                ({formatNumber(Math.round(leaseIncomeTaxReduction))}) ₽
              </div>
            </td>
          </tr>
          {tableOpen && Interest}
          {tableOpen && (
            <tr className='contents'>
              <td className='w-40 ml-6 mt-1px lg:w-auto border-b-1 border-grayscale-300 py-3 text-p250 font-normal flex items-end justify-between mr-1'>
                Расходы на амортизацию
                <Tooltip className='ml-1 self-center'>
                  Когда компания покупает оборудование, его амортизацию можно
                  списать в расходы для уменьшения налогов. Срок и размер
                  амортизации зависит от группы оборудования. В примере срок
                  полезного использования равен трём годам. К лизинговому
                  оборудованию можно применить ускоренную амортизацию. (ст.259.3
                  НК РФ)
                </Tooltip>
              </td>
              <td className='w-38 lg:w-48 border-l-2 border-r-2 border-red-100 border-opacity-20 px-4 lg:px-5 pt-3'>
                <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-red-100 border-opacity-20'>
                  {formatNumber(Math.round(depreciation))} ₽
                </div>
              </td>
              <td className='w-38 lg:w-48 border-l-2 border-r-2 border-yellow-500 border-opacity-20 px-4 lg:px-5 pt-3'>
                <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-yellow-500 border-opacity-20'>
                  {formatNumber(Math.round(depreciation))} ₽
                </div>
              </td>
              <td className='w-38 lg:w-48 border-l-2 border-r-2 border-green-400 border-opacity-30 px-4 lg:px-5 pt-3'>
                <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-green-500 border-opacity-30'>
                  {formatNumber(Math.round(leaseDepreciation))} ₽
                </div>
              </td>
            </tr>
          )}
          <tr className='contents'>
            <td className='w-46 mt-1px lg:w-auto border-b-1 border-grayscale-300 py-3 text-p250 font-normal flex items-end justify-between mr-1'>
              Сумма НДС к вычету
              <Tooltip className='ml-1 self-center'>
                Кредит: НДС вычитается <b>сразу</b> со всей стоимости
                оборудования <b>без учёта</b> платы за финансирование
                <br />
                <br />
                Собственные средства: НДС вычитается <b>сразу</b> со всей
                стоимости оборудования
                <br />
                <br />
                Лизинг: НДС вычитается <b>ежемесячно</b> с каждого платежа{' '}
                <b>с учетом</b> платы за финансирование
              </Tooltip>
            </td>
            <td className='w-38 lg:w-48 border-l-2 border-r-2 border-red-100 border-opacity-20 px-4 lg:px-5 pt-3'>
              <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-red-100 border-opacity-0'>
                ({formatNumber(Math.round(VATReduction))}) ₽
              </div>
            </td>
            <td className='w-38 lg:w-48 border-l-2 border-r-2 border-yellow-500 border-opacity-20 px-4 lg:px-5 pt-3'>
              <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-yellow-500 border-opacity-0'>
                ({formatNumber(Math.round(VATReduction))}) ₽
              </div>
            </td>
            <td className='w-38 lg:w-48 border-l-2 border-r-2 border-green-400 border-opacity-30 px-4 lg:px-5 pt-3'>
              <div className='px-1 pb-3 lg:px-2 border-b-1 h-full border-green-500 border-opacity-0'>
                ({formatNumber(Math.round(leaseVATReduction))}) ₽
              </div>
            </td>
          </tr>

          <tr className='contents'>
            <td className='w-46 lg:w-auto py-3 text-p250 font-normal flex items-center justify-between mr-1'>
              Итого стоимость приобретённого имущества
              <Tooltip className='ml-1 self-center'>
                Итоговая стоимость имущества считается как:
                <table className='mt-2 table-fixed'>
                  <thead className='text-left'>
                    <tr>
                      <th className='text-center'></th>
                      <th className='pl-3'>Стоимость имущества</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center font-bold'>+</td>
                      <td className='pl-3'>Ставка финансирования</td>
                    </tr>
                    <tr>
                      <td className='text-center font-bold'>–</td>
                      <td className='pl-3'>Экономия по налогу на прибыль</td>
                    </tr>
                    <tr>
                      <td className='text-center font-bold'>–</td>
                      <td className='pl-3'>НДС к вычету</td>
                    </tr>
                    <tr>
                      <td className='text-center font-bold'>+</td>
                      <td className='pl-3'>Процент на вложенный капитал</td>
                    </tr>
                  </tbody>
                </table>
              </Tooltip>
            </td>
            <td className='w-38 lg:w-48 ont-display text-h300 font-medium rounded-b-xl border-2 border-red-100 border-t-0 border-opacity-20 px-5 lg:px-7 pb-4 lg:pb-6 pt-6'>
              {formatNumber(
                Math.round(
                  amount + loanInterest - VATReduction - incomeTaxReduction
                )
              )}
               <span className='font-normal text-h350'>₽</span>
            </td>
            <td className='w-38 lg:w-48 ont-display text-h300 font-medium rounded-b-xl border-2 border-yellow-500 border-t-0 border-opacity-20 px-5 lg:px-7 pb-4 lg:pb-6 pt-6'>
              {formatNumber(
                Math.round(
                  amount -
                    VATReduction -
                    ownIncomeTaxReduction +
                    capitalInterest
                )
              )}
               <span className='font-normal text-h350'>₽</span>
            </td>
            <td className='w-38 lg:w-48 ont-display text-h300 font-medium rounded-b-xl border-2 border-green-400 border-t-0 border-opacity-30 px-5 lg:px-7 pb-4 lg:pb-6 pt-6'>
              {formatNumber(
                Math.round(
                  amount +
                    leaseInterest -
                    leaseVATReduction -
                    leaseIncomeTaxReduction
                )
              )}
               <span className='font-normal text-h350'>₽</span>
            </td>
          </tr>
        </tbody>
      </table>
      <p className='text-p450 text-grayscale-200 px-6 xl:px-0'>
        *Все расчеты несут ознакомительный характер и не являются публичной
        офертой.
      </p>
    </div>
  )
}

export default Comparison
