import React, { FC, useState, useRef, ComponentProps } from 'react'
import c from 'clsx'
import tooltipStyles from './Tooltip.module.css'
import { Portal } from '@headlessui/react'
import { useWindowEvent } from '@hooks/useWindowEvent'
import useToggle from '@hooks/useToggle'
import { usePopper } from 'react-popper'

const Tooltip: FC<ComponentProps<'div'>> = ({ children, className, ...props }) => {
  const [isOpen, toggle, setOpen] = useToggle(false)

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      { name: 'offset', options: { offset: [0, 15] } },
      { name: 'arrow', options: { element: arrowElement, padding: 30 } },
      {
        name: 'flip',
        options: {
          padding: { top: 90, right: 30, bottom: 30, left: 30 },
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 30,
          tetherOffset: 30 + 8 // arrow padding + reference width / 2
        },
      }
    ]
  })

  // закрываем подсказки, когда пользователь кликает вне их
  useWindowEvent('mousedown', event => {
    if (popperElement?.contains(event.target as Node)) return
    if (referenceElement?.contains(event.target as Node)) return

    setOpen(false)
  })

  return (
    <div {...props} ref={setReferenceElement} className={c('relative', className)}>
      <div className='text-grayscale-250 hover:text-grayscale-150'>
        <svg
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          onPointerDown={toggle}
          width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="8" fill="currentColor" />
          <path d="M6.64497 10.0071H8.30052V9.87782C8.31046 8.82384 8.6535 8.3416 9.46387 7.84444C10.3786 7.29756 10.9504 6.55182 10.9504 5.41333C10.9504 3.73293 9.64284 2.67896 7.7437 2.67896C6.00364 2.67896 4.59171 3.64344 4.54199 5.53265H6.31188C6.35662 4.59799 7.03276 4.15055 7.73375 4.15055C8.49441 4.15055 9.10591 4.65765 9.10591 5.43819C9.10591 6.13421 8.64355 6.61148 8.04199 6.98932C7.16202 7.5362 6.65492 8.08805 6.64497 9.87782V10.0071ZM7.515 13.1094C8.10165 13.1094 8.60378 12.6221 8.60875 12.0156C8.60378 11.419 8.10165 10.9318 7.515 10.9318C6.90847 10.9318 6.41628 11.419 6.42125 12.0156C6.41628 12.6221 6.90847 13.1094 7.515 13.1094Z" fill="white" />
        </svg>
      </div>
      {isOpen &&
        <Portal>
          <div style={styles.popper} {...attributes.popper} className='absolute z-50' ref={setPopperElement}>
            <div className='p-4 text rounded-lg text-white-0 leading-6 bg-grayscale-0 sm:max-w-md max-w-screen-p-6'>
              {children}
            </div>
            <div style={styles.arrow} className={tooltipStyles.arrow} ref={setArrowElement} />
          </div></Portal>}

    </div>
  )
}



export default Tooltip